import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../assets/mainlogo.png";

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <header className="max-w-7xl mx-auto py-3">
      <div className="flex items-center justify-between px-4">
        {/* Logo Section */}
        <div className="flex items-center">
          <Link to="/" className="text-gray-700 hover:text-red-700">
            <img src={logo} alt="Sival DevOps" className="h-12" />
          </Link>
        </div>

        {/* Navigation Links */}
        <div>
          {/* Desktop Navigation */}
          <nav className="hidden lg:flex space-x-6 items-center">
            <Link to="/" className="text-gray-700 hover:text-red-700">
              Home
            </Link>
            <Link to="/oursolution" className="text-red-700 font-semibold">
              Our Solution
            </Link>
            <Link to="/otherfield" className="text-gray-700 hover:text-red-700">
              Our Squarebric
            </Link>
            <Link
              to="/contactus"
              className="bg-red-700 text-white px-4 py-2 rounded-full hover:bg-red-600 transition"
            >
              Contact Us
            </Link>
          </nav>

          {/* Mobile Navigation - Hamburger Menu */}
          <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-gray-700 focus:outline-none"
            >
              {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>

          {/* Mobile Menu (Dropdown) */}
          <div
            className={`lg:hidden flex flex-col absolute top-16 left-0 z-20 bg-white h-[100%] shadow-md rounded-lg w-full p-4 space-y-4 transition-transform duration-300 ease-in-out ${
              isMobileMenuOpen
                ? "opacity-100 translate-x-0"
                : "opacity-0 -translate-x-full pointer-events-none"
            }`}
          >
            <Link
              to="/"
              className="text-gray-700 hover:text-red-700"
              onClick={toggleMobileMenu}
            >
              Home
            </Link>
            <Link
              to="/oursolution"
              className="text-red-700 font-semibold"
              onClick={toggleMobileMenu}
            >
              Our Solution
            </Link>
            <Link
              to="/otherfield"
              className="text-gray-700 hover:text-red-700"
              onClick={toggleMobileMenu}
            >
              Our Other Field
            </Link>
            
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
