import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

// Lazy load components
const HeroSection = React.lazy(() => import("./components/header/HeroSection"));
const Otherfield = React.lazy(() => import("./OurOtherFields/Otherfield"));
const Career = React.lazy(() => import("./components/pages/carrer/Career"));
const Contactus = React.lazy(() => import("./components/pages/contactus/Contactus"));
const Trafficmanagemnt = React.lazy(() => import("./components/pages/trafficmanagement/Trafficmanagemnt"));
const Smokefire = React.lazy(() => import("./components/pages/smokeand fire/Smokefire"));
const Facerecognition = React.lazy(() => import("./components/pages/facerecognition/Facerecognition"));
const About = React.lazy(() => import("./components/about/About"));
const Dwellanalysis = React.lazy(() => import("./components/pages/dwelltimeanalysis/Dwellanalysis"));
const Oursolution = React.lazy(() => import("./components/pages/Oursolutions/Oursolution"));
const Crowd = React.lazy(() => import("./components/pages/crowd/Crowd"));
const Gundetection = React.lazy(() => import("./components/pages/gundetection/Gundetection"));

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HeroSection />} />
              <Route path="/otherfield" element={<Otherfield />} />
              <Route path="/career" element={<Career />} />
           
              <Route path="/contactus" element={<Contactus />} />
              <Route path="/trafficmanagemnt" element={<Trafficmanagemnt />} />
              <Route path="/smokefire" element={<Smokefire />} />
              <Route path="/facerecognition" element={<Facerecognition />} />
              <Route path="/dwellanalysis" element={<Dwellanalysis />} />
              <Route path="/oursolution" element={<Oursolution />} />
              <Route path="/about" element={<About />} />
              <Route path="/crowd" element={<Crowd />} />
              <Route path="/gundetection" element={<Gundetection />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;