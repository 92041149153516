import React from "react";
import {
  FaPhone,
  FaEnvelope,
  FaInstagram,
  FaFacebook,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../assets/mainlogo.png";
import bg from "../../assets/bg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
function Footer() {
  return (
    <footer
      className="bg-gradient-to-r from-blue-900 via-purple-900 to-red-900 py-10 text-white h-auto"
      style={{
        backgroundImage: `linear-gradient(255.34deg, #310303 2.94%, #001F40 105.06%), url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8 max-w-7xl w-full ">
        {/* Logo and Description Section */}
        <div className="flex flex-col space-y-4 bg-[#FFFFFF] rounded-[20px] h-auto">
          <LazyLoadImage
            src={logo}
            alt="Sival DevOps"
            className="h-20 w-auto rounded-[20px]"
          />
          <p className="text-[#1A294F] px-2">
            Sival Devops is a leading provider of innovative business software
            solutions, information technology, and consulting services.
          </p>
        </div>

        {/* Company Section */}
        <div className="flex flex-col space-y-3">
          <h4 className="font-semibold">Company</h4>
          <Link to="/contactus" className="text-gray-400 hover:text-gray-200">
            Contact Us
          </Link>

          <Link to="/about" className="text-gray-400 hover:text-gray-200">
            About Us
          </Link>
          <Link to="#" className="text-gray-400 hover:text-gray-200">
            Blog & News
          </Link>
        </div>

        {/* Quick Links Section */}
        <div className="flex flex-col space-y-3">
          <h4 className="font-semibold">Quick Links</h4>
          <Link to="/career" className="text-gray-400 hover:text-gray-200">
            Career
          </Link>
          <Link to="#" className="text-gray-400 hover:text-gray-200">
            Free Demo
          </Link>
          <Link to="#" className="text-gray-400 hover:text-gray-200">
            Press Conferences
          </Link>
        </div>

        {/* Reach Out Section */}
        <div className="flex flex-col space-y-3">
          <h4 className="font-semibold">Reach Out</h4>
          <div className="flex items-center space-x-2">
            <FaPhone className="text-gray-400" />
            <span className="text-gray-400">+919026716969</span>
          </div>
          <div className="flex items-center space-x-2">
            <FaEnvelope className="text-gray-400" />
            <span className="text-gray-400">info@sivaldevops.com</span>
          </div>
          <div className="flex items-center space-x-2">
            <p className="text-gray-400 capitalize">
              Sival Devops <br />
              15, 2nd Cross Road, Hig Colony, RMV 2nd Stage Dollars Colony
              Bangalore -560094,Karnatka, India
            </p>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="border-t border-gray-600 mt-8 py-4 ">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between max-w-7xl w-full px-4">
          <p className="text-gray-400">Copyright © 2024 - Sival Devops</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <Link href="#" className="text-gray-400 hover:text-gray-200">
              <FaInstagram size={20} />
            </Link>
            <Link href="#" className="text-gray-400 hover:text-gray-200">
              <FaFacebook size={20} />
            </Link>
            <Link href="#" className="text-gray-400 hover:text-gray-200">
              <FaTwitter size={20} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
